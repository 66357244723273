<!-- 营收统计 站点-->
<template>
  <div class="a-mt-60">
    <div class="a-flex-rsbc a-pt-25">
      <div class="a-flex-rfsfe">
        <span class="a-c-master a-fw-700 a-fs-16">营收统计</span>
        <span class="a-c-normal a-fs-14 a-ml-10">(单位: 元)</span>
      </div>
    </div>
    <div class="a-flex-rfsc a-mt-25">
      <div class="a-flex-cfsfs" style="margin-right: 107px">
        <span class="a-fs-14 a-c-normal">历史总收益（元）</span>
        <div class="a-flex-rfsb a-mt-10">
          <span class="a-fs-24 a-c-master a-fw-700">￥</span>
          <span class="a-fs-32 a-c-master a-fw-700">
            {{
              (revenueStatistics && revenueStatistics.hisOrderAmount
                ? revenueStatistics.hisOrderAmount
                : 0) | initMoney
            }}
          </span>
        </div>
      </div>
      <div class="a-flex-cfsfs" style="margin-right: 107px">
        <span class="a-fs-14 a-c-normal">历史平均设备收益（元）</span>
        <div class="a-flex-rfsb a-mt-10">
          <span class="a-fs-24 a-c-master a-fw-700">￥</span>
          <span class="a-fs-32 a-c-master a-fw-700">
            {{
              (revenueStatistics && revenueStatistics.perDeviceAmount
                ? revenueStatistics.perDeviceAmount
                : 0) | initMoney
            }}
          </span>
          <span class="a-fs-14 a-c-normal">/台/天</span>
        </div>
      </div>
      <div class="a-flex-cfsfs">
        <span class="a-fs-14 a-c-normal">历史平均营收（元）</span>
        <div class="a-flex-rfsb a-mt-10">
          <span class="a-fs-24 a-c-master a-fw-700">￥</span>
          <span class="a-fs-32 a-c-master a-fw-700">
            {{
              (revenueStatistics && revenueStatistics.perDayOrderAmount
                ? revenueStatistics.perDayOrderAmount
                : 0) | initMoney
            }}
          </span>
          <span class="a-fs-14 a-c-normal">/天</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  filters: {
    initMoney(val) {
      let num = Number(val);
      if (!num) {
        //等于0
        return "0.00";
      } else {
        //不等于0
        num = Math.round(num * 100) / 10000;
        num = num.toFixed(2);
        num += ""; //转成字符串
        const reg =
          num.indexOf(".") > -1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g; //千分符的正则
        // console.log(num.indexOf('.')>-1)
        return num.replace(reg, "$1,"); //千分位格式化
      }
    },
  },
  props: ["revenueStatistics"],
};
</script>

<style lang="scss" scoped></style>
