<!-- 单桩收益站点分布 -->
<template>
  <div class="a-mt-60">
    <div class="a-flex-rsbc a-pt-25">
      <div class="a-flex-rfsfe">
        <span class="a-c-master a-fw-700 a-fs-16">单桩收益站点分布</span>
      </div>
      <div class="a-flex-rfsc">
        <div class="tips"></div>
        <span class="a-c-normal a-fs-14 a-ml-11">站点数</span>
      </div>
    </div>
    <div id="mychartStation" class="mychart a-mt-32"></div>
  </div>
</template>

<script>
export default {
  props: ["id", "params"],
  data() {
    return {
      params_req: "",
    };
  },
  watch: {
    params: {
      handler(val) {
        this.params_req = JSON.parse(JSON.stringify(val));
        this.getChartData();
      },
    },
  },
  mounted() {},
  methods: {
    drawChart(xAxis = [], data = []) {
      const chartDom = document.getElementById("mychartStation");
      const option = {
        grid: {
          left: "0",
          right: "0",
          bottom: "0",
          top: "36px",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          axisLine: {
            lineStyle: {
              color: "#DDDDDD",
            },
          },
          axisTick: {
            lineStyle: {
              color: "#DDDDDD",
            },
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            color: "#999999",
            showMaxLabel: true,
          },
          data: xAxis,
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              color: "#EEEEEE",
              type: "dashed",
            },
          },
        },
        series: [
          {
            data: data,
            type: "bar",
            barWidth: 19,
            label: {
              normal: {
                show: true,
                position: "top",
                textStyle: {
                  color: "#606366",
                },
              },
            },
            itemStyle: {
              normal: {
                color: "#6699FF",
                barBorderRadius: [4, 4, 0, 0],
              },
            },
          },
        ],
      };
      const myChart = this.$echarts.init(chartDom);

      myChart.setOption(option);
    },
    getChartData() {
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.getStatDeviceAmountDistriDetailList,
          method: "post",
          params: this.params_req,
        })
        .then((res) => {
          if (res.result.code == 0) {
            const xAxis = [];
            const datas = [];
            res.result.data.map((item) => {
              xAxis.push(Number(item.perAmount).toFixed(1) + "元");
              datas.push(item.stationNum);
            });
            this.drawChart(xAxis, datas);
          } else {
            this.$message.error(res.result.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.tips {
  width: 11px;
  height: 11px;
  background: #6699ff;
  border-radius: 3px;
}
.mychart {
  width: 100%;
  height: 300px;
}
</style>
