<template>
  <div class="app-body">
    <bread-crumb></bread-crumb>
    <el-card class="el-main">
      <span class="a-fw-700 a-c-master title">{{ title }}</span>
      <div class="line"></div>
      <earnings-trend :id="id" :params="params"></earnings-trend>
      <revenue-statistics
        v-if="infoType != 'station'"
        :revenue-statistics="revenueStatistics"
      ></revenue-statistics>
      <revenue-statistics-station
        v-if="infoType == 'station'"
        :revenue-statistics="revenueStatistics"
      ></revenue-statistics-station>
      <income-distribution
        v-if="infoType != 'station'"
        :id="id"
        :params="params"
      ></income-distribution>
      <income-distribution-station
        v-if="infoType != 'station'"
        :id="id"
        :params="params"
      ></income-distribution-station>
      <charging-distribution :id="id" :params="params"></charging-distribution>
      <dev-situation
        v-if="infoType != 'station'"
        :id="id"
        :params="params"
        :revenue-statistics="revenueStatistics"
      ></dev-situation>
      <dev-offline v-if="infoType != 'station'" :id="id" :params="params"></dev-offline>
      <dev-install v-if="infoType != 'station'" :id="id" :params="params"></dev-install>
      <station-dev-situation
        v-if="infoType == 'station'"
        :revenue-statistics="revenueStatistics"
      ></station-dev-situation>
      <station-baseinfo v-if="infoType == 'station'" :params="params"></station-baseinfo>
    </el-card>
  </div>
</template>

<script>
import ChargingDistribution from "./info-child/chargingDistribution.vue";
import DevInstall from "./info-child/devInstall.vue";
import DevOffline from "./info-child/devOffline.vue";
import DevSituation from "./info-child/devSituation.vue";
import EarningsTrend from "./info-child/earningsTrend.vue";
import IncomeDistribution from "./info-child/incomeDistribution.vue";
import IncomeDistributionStation from "./info-child/incomeDistributionStation.vue";
import RevenueStatistics from "./info-child/revenueStatistics.vue";
import RevenueStatisticsStation from "./info-child/revenueStatisticsStation.vue";
import StationBaseinfo from "./info-child/station-baseinfo.vue";
import StationDevSituation from "./info-child/stationDevSituation.vue";
export default {
  components: {
    EarningsTrend,
    RevenueStatistics,
    IncomeDistribution,
    IncomeDistributionStation,
    ChargingDistribution,
    DevSituation,
    DevOffline,
    DevInstall,
    StationBaseinfo,
    StationDevSituation,
    RevenueStatisticsStation,
  },
  data() {
    return {
      infoType: "",
      id: "", //详情查询id
      title: "", //页面头部
      revenueStatistics: "",
      params: {}, // 图表详情请求参数
    };
  },
  mounted() {
    this.infoType = this.$route.query.type;
    this.title = this.$route.query.title;
    this.id = this.$route.query.id;
    this.revenueStatistics = JSON.parse(this.$route.query.revenueStatistics);
    switch (this.infoType) {
      case "province":
        this.params = {
          provinceCode: this.id,
        };
        break;
      case "project":
        this.params = {
          incomeId: this.id,
        };
        break;
      case "city":
        this.params = {
          cityCode: this.id,
        };
        break;
      case "business":
        this.params = {
          companyId: this.id,
        };
        break;
      case "station":
        this.params = {
          stationId: this.id,
          ownerCompanyId: this.revenueStatistics.companyId,
        };
        break;
      default:
        break;
    }
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
}
.line {
  width: 100%;
  height: 1px;
  border: 1px solid #ebf0f5;
  margin-top: 21px;
}
</style>
