<!-- 营收统计 -->
<template>
  <div class="a-mt-60">
    <div class="a-flex-rsbc a-pt-25">
      <div class="a-flex-rfsfe">
        <span class="a-c-master a-fw-700 a-fs-16">营收统计</span>
        <span class="a-c-normal a-fs-14 a-ml-10">(单位: 元)</span>
      </div>
    </div>
    <div class="a-flex-rfsc a-mt-25">
      <div class="a-flex-cfsfs" style="margin-right: 107px">
        <span class="a-fs-14 a-c-normal">历史总收益（元）</span>
        <div class="a-flex-rfsb a-mt-10 familyDin">
          <span class="a-fs-24 a-c-master a-fw-700">￥</span>
          <span class="a-fs-32 a-c-master a-fw-700">
            {{
              (revenueStatistics && revenueStatistics.hisOrderAmount
                ? revenueStatistics.hisOrderAmount
                : 0) | initMoney
            }}
          </span>
        </div>
      </div>
      <div class="a-flex-cfsfs" style="margin-right: 107px">
        <span class="a-fs-14 a-c-normal">历史单桩收益（元）</span>
        <div class="a-flex-rfsb a-mt-10 familyDin">
          <span class="a-fs-24 a-c-master a-fw-700">￥</span>
          <span class="a-fs-32 a-c-master a-fw-700">
            {{
              (revenueStatistics && revenueStatistics.perDeviceAmount
                ? revenueStatistics.perDeviceAmount
                : 0) | initMoney
            }}
          </span>
          <span class="a-fs-14 a-c-normal">/台/天</span>
        </div>
      </div>
      <div class="a-flex-cfsfs">
        <span class="a-fs-14 a-c-normal">历史平均营收（元）</span>
        <div class="a-flex-rfsb a-mt-10 familyDin">
          <span class="a-fs-24 a-c-master a-fw-700">￥</span>
          <span class="a-fs-32 a-c-master a-fw-700">
            {{
              (revenueStatistics && revenueStatistics.perDayOrderAmount
                ? revenueStatistics.perDayOrderAmount
                : 0) | initMoney
            }}
          </span>
          <span class="a-fs-14 a-c-normal">/天</span>
        </div>
      </div>
    </div>
    <div class="a-flex-rfsc a-mt-32">
      <div class="a-flex-rsbc a-flex-1 a-plr-40 a-flex-wrap border-lt border-r">
        <div class="a-flex-cfsfs a-ptb-30">
          <span class="a-fs-14 a-c-normal">30天总收益</span>
          <div class="a-flex-rfsb a-mt-10">
            <span class="a-fs-21 a-c-master a-fw-700">￥</span>
            <span class="a-fs-24 a-c-master a-fw-700">
              {{
                (revenueStatistics && revenueStatistics.day30OrderAmount
                  ? revenueStatistics.day30OrderAmount
                  : 0) | initMoney
              }}
            </span>
          </div>
        </div>

        <div class="a-flex-cfsfs a-ptb-30">
          <span class="a-fs-14 a-c-normal">30天单桩收益</span>
          <div class="a-flex-rfsb a-mt-10">
            <span class="a-fs-21 a-c-master a-fw-700">￥</span>
            <span class="a-fs-24 a-c-master a-fw-700">
              {{
                (revenueStatistics && revenueStatistics.perDeviceAmount30
                  ? revenueStatistics.perDeviceAmount30
                  : 0) | initMoney
              }}
            </span>
            <span class="a-fs-14 a-c-normal">/台/天</span>
          </div>
        </div>

        <div class="a-flex-cfsfs a-ptb-30">
          <span class="a-fs-14 a-c-normal">30天订单量</span>
          <div class="a-flex-rfsb a-mt-10">
            <span class="a-fs-24 a-c-master a-fw-700">
              {{
                revenueStatistics && revenueStatistics.day30OrderNum
                  ? revenueStatistics.day30OrderNum
                  : 0
              }}
            </span>
          </div>
        </div>
      </div>
      <div class="a-flex-rsbc a-flex-1 a-plr-40 a-flex-wrap border-rt">
        <div class="a-flex-cfsfs a-ptb-30">
          <span class="a-fs-14 a-c-normal">本月总收益</span>
          <div class="a-flex-rfsb a-mt-10">
            <span class="a-fs-21 a-c-master a-fw-700">￥</span>
            <span class="a-fs-24 a-c-master a-fw-700">
              {{
                (revenueStatistics && revenueStatistics.monthOrderAmount
                  ? revenueStatistics.monthOrderAmount
                  : 0) | initMoney
              }}
            </span>
          </div>
        </div>

        <div class="a-flex-cfsfs a-ptb-30">
          <span class="a-fs-14 a-c-normal">本月单桩收益</span>
          <div class="a-flex-rfsb a-mt-10">
            <span class="a-fs-21 a-c-master a-fw-700">￥</span>
            <span class="a-fs-24 a-c-master a-fw-700">
              {{
                (revenueStatistics && revenueStatistics.perDeviceAmountMonth
                  ? revenueStatistics.perDeviceAmountMonth
                  : 0) | initMoney
              }}
            </span>
            <span class="a-fs-14 a-c-normal">/台/天</span>
          </div>
        </div>

        <div class="a-flex-cfsfs a-ptb-30">
          <span class="a-fs-14 a-c-normal">本月订单量</span>
          <div class="a-flex-rfsb a-mt-10">
            <span class="a-fs-24 a-c-master a-fw-700">
              {{
                revenueStatistics && revenueStatistics.monthOrderNum
                  ? revenueStatistics.monthOrderNum
                  : 0
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%; height: 1px; background: #e3e3e3"></div>
    <div class="a-flex-rfsc">
      <div class="a-flex-rsbc a-flex-1 a-plr-40 a-flex-wrap border-lb border-r">
        <div class="a-flex-cfsfs a-ptb-30">
          <span class="a-fs-14 a-c-normal">7天总收益</span>
          <div class="a-flex-rfsb a-mt-10">
            <span class="a-fs-21 a-c-master a-fw-700">￥</span>
            <span class="a-fs-24 a-c-master a-fw-700">
              {{
                (revenueStatistics && revenueStatistics.day7OrderAmount
                  ? revenueStatistics.day7OrderAmount
                  : 0) | initMoney
              }}
            </span>
          </div>
        </div>

        <div class="a-flex-cfsfs a-ptb-30">
          <span class="a-fs-14 a-c-normal">7天单桩收益</span>
          <div class="a-flex-rfsb a-mt-10">
            <span class="a-fs-21 a-c-master a-fw-700">￥</span>
            <span class="a-fs-24 a-c-master a-fw-700">
              {{
                (revenueStatistics && revenueStatistics.perDeviceAmount7
                  ? revenueStatistics.perDeviceAmount7
                  : 0) | initMoney
              }}
            </span>
            <span class="a-fs-14 a-c-normal">/台/天</span>
          </div>
        </div>

        <div class="a-flex-cfsfs a-ptb-30">
          <span class="a-fs-14 a-c-normal">7天订单量</span>
          <div class="a-flex-rfsb a-mt-10">
            <span class="a-fs-24 a-c-master a-fw-700">
              {{
                revenueStatistics && revenueStatistics.day7OrderNum
                  ? revenueStatistics.day7OrderNum
                  : 0
              }}
            </span>
          </div>
        </div>
      </div>
      <div class="a-flex-rsbc a-flex-1 a-plr-40 a-flex-wrap border-rb">
        <div class="a-flex-cfsfs a-ptb-30">
          <span class="a-fs-14 a-c-normal">昨日总收益</span>
          <div class="a-flex-rfsb a-mt-10">
            <span class="a-fs-21 a-c-master a-fw-700">￥</span>
            <span class="a-fs-24 a-c-master a-fw-700">
              {{
                (revenueStatistics && revenueStatistics.yesterdayOrderAmount
                  ? revenueStatistics.yesterdayOrderAmount
                  : 0) | initMoney
              }}
            </span>
          </div>
        </div>

        <div class="a-flex-cfsfs a-ptb-30">
          <span class="a-fs-14 a-c-normal">昨日平均时长</span>
          <div class="a-flex-rfsb a-mt-10">
            <span class="a-fs-24 a-c-master a-fw-700">
              {{
                revenueStatistics && revenueStatistics.yesterdayOrderAvgTime
                  ? revenueStatistics.yesterdayOrderAvgTime
                  : 0
              }}
            </span>
            <span class="a-fs-14 a-c-normal">分钟</span>
          </div>
        </div>

        <div class="a-flex-cfsfs a-ptb-30">
          <span class="a-fs-14 a-c-normal">昨日订单量</span>
          <div class="a-flex-rfsb a-mt-10">
            <span class="a-fs-24 a-c-master a-fw-700">
              {{
                revenueStatistics && revenueStatistics.yesterdayOrderNum
                  ? revenueStatistics.yesterdayOrderNum
                  : 0
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  filters: {
    initMoney(val) {
      let num = Number(val);
      if (!num) {
        //等于0
        return "0.00";
      } else {
        //不等于0
        num = Math.round(num * 100) / 10000;
        num = num.toFixed(2);
        num += ""; //转成字符串
        const reg =
          num.indexOf(".") > -1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g; //千分符的正则
        // console.log(num.indexOf('.')>-1)
        return num.replace(reg, "$1,"); //千分位格式化
      }
    },
  },
  props: ["revenueStatistics"],
};
</script>

<style lang="scss" scoped>
.border-lt {
  border-left: 1px solid #e3e3e3;
  border-top: 1px solid #e3e3e3;
}
.border-rt {
  border-right: 1px solid #e3e3e3;
  border-top: 1px solid #e3e3e3;
}
.border-lb {
  border-left: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
}
.border-rb {
  border-right: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
}
.border-r {
  border-right: 1px solid #e3e3e3;
}
</style>
