<!-- 单桩收益分布 -->
<template>
  <div class="a-mt-60">
    <div class="a-flex-rsbc a-pt-25">
      <div class="a-flex-rfsfe">
        <span class="a-c-master a-fw-700 a-fs-16">单桩收益分布</span>
      </div>
    </div>
    <el-table :data="tableData" border class="a-mt-32">
      <el-table-column label="">
        <template slot-scope="scope">
          <span v-if="scope.$index == 0">站点数量</span>
          <span v-if="scope.$index == 1">设备总数</span>
          <span v-if="scope.$index == 2">在线数量</span>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in headData"
        :key="index"
        :label="item.perAmount + '元'"
      >
        <template slot-scope="{ row }">
          <span>{{ row[item.perAmount] }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: ["id", "params"],
  data() {
    return {
      tableData: [null, null, null],
      headData: [], //表头数据
      params_req: "",
    };
  },
  watch: {
    params: {
      handler(val) {
        this.params_req = JSON.parse(JSON.stringify(val));
        this.getTableData();
      },
    },
  },
  methods: {
    getTableData() {
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.getStatDeviceAmountDistriList,
          method: "post",
          params: this.params_req,
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.headData = res.result.data;
            const row1 = {};
            const row2 = {};
            const row3 = {};
            this.headData.map((item) => {
              row1[item.perAmount] = item.stationNum;
              row2[item.perAmount] = item.totalDeviceNum;
              row3[item.perAmount] = item.onlineDeviceNum;
            });
            this.tableData = [row1, row2, row3];
          } else {
            this.$message.error(res.result.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-table__header th.el-table__cell {
  background: #ffffff !important;
  color: #606366;
  font-weight: 400;
}
</style>
