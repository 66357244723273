<!-- 收益趋势 -->
<template>
  <div class="a-mt-36">
    <div class="a-flex-rsbc">
      <div class="a-flex-rfsfe">
        <span class="a-c-master a-fw-700 a-fs-16">收益趋势</span>
        <span class="a-c-normal a-fs-14 a-ml-10">(单位: 元)</span>
      </div>
      <le-date-range :min-date.sync="queryStartDate" :max-date.sync="queryEndDate" />
    </div>
    <div class="a-flex-rfsc a-mt-25">
      <div class="a-flex-cfsfs" style="margin-right: 107px">
        <span class="a-fs-14 a-c-normal">总交易额（元）</span>
        <div class="a-flex-rfsb a-mt-10 familyDin">
          <span class="a-fs-24 a-c-master">￥</span>
          <span class="a-fs-32 a-c-master a-fw-700">{{ moneyNum | initMoney }}</span>
        </div>
      </div>
      <div class="a-flex-cfsfs">
        <span class="a-fs-14 a-c-normal">总订单量（笔）</span>
        <span class="a-fs-32 a-c-master a-mt-10 a-fw-700 familyDin">{{ orderNum }}</span>
      </div>
    </div>
    <div class="a-flex-rfec a-mt-16">
      <div class="a-flex-rfsc">
        <div class="tips2"></div>
        <span class="a-c-normal a-fs-14 a-ml-11">交易额</span>
      </div>
      <div class="a-flex-rfsc a-ml-40">
        <div class="tips"></div>
        <span class="a-c-normal a-fs-14 a-ml-11">订单量</span>
      </div>
    </div>
    <div id="mychart" class="mychart"></div>
  </div>
</template>

<script>
export default {
  filters: {
    initMoney(val) {
      let num = Number(val);
      if (!num) {
        //等于0
        return num + ".00";
      } else {
        //不等于0
        num = Math.round(num * 100) / 10000;
        num = num.toFixed(2);
        num += ""; //转成字符串
        const reg =
          num.indexOf(".") > -1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g; //千分符的正则
        // console.log(num.indexOf('.')>-1)
        return num.replace(reg, "$1,"); //千分位格式化
      }
    },
  },
  props: ["id", "params"],
  data() {
    return {
      queryStartDate: "",
      queryEndDate: "",
      moneyNum: "", //
      orderNum: "",
      params_req: "",
    };
  },
  computed: {
    queryDate() {
      return this.queryStartDate + "#" + this.queryEndDate;
    },
  },
  watch: {
    queryDate(newval, oldval) {
      if (newval != oldval) {
        this.getTotal();
        this.getChartData();
      }
    },
    params: {
      handler(val) {
        this.params_req = JSON.parse(JSON.stringify(val));
        // 如果存在日期选择区间则使用该日期选择区间，如果不存在则使用当前日期前30天作为日期选择区间
        if (!this.queryStartDate && !this.queryEndDate) {
          const toData = new Date(new Date().toLocaleDateString()).getTime();
          const past30daysStart = toData - 30 * 3600 * 24 * 1000;
          this.queryStartDate = this.$Utils.formatDate(past30daysStart, "yyyy-MM-dd");
          this.queryEndDate = this.$Utils.formatDate(toData, "yyyy-MM-dd");
        }
        this.getTotal();
        this.getChartData();
      },
    },
  },
  methods: {
    setMoney(val) {
      let num = Number(val);
      if (!num) {
        //等于0
        return num + ".00";
      } else {
        //不等于0
        // num = Math.round((num)*100)/10000;
        // num = num.toFixed(2);
        num += ""; //转成字符串
        const reg =
          num.indexOf(".") > -1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g; //千分符的正则
        // console.log(num.indexOf('.')>-1)
        return num.replace(reg, "$1,"); //千分位格式化
      }
    },
    drawChart(xAxis = [], dataTrading = [], dataOrder = []) {
      //横坐标, 交易金额，订单数量
      const chartDom = document.getElementById("mychart");
      const option = {
        tooltip: {
          trigger: "axis",
          padding: 0,
          backgroundColor: "transparent",
          formatter: (item) => {
            const htmlContxt =
              `<div class="a-flex-cfsfs" style='padding:10px 18px;background:#FFFFFF;box-shadow: 0px 5px 21px 0px rgba(139,147,167,0.25);border-radius:6px'>
                                <span style='font-size: 14px;color: #606366;'>` +
              item[0].name +
              `</span>` +
              `<span style='font-size: 17px;font-weight: 700;color: #303133;padding:10px 0'>￥` +
              this.setMoney(item[0].data) +
              `<span style='font-size: 16px;font-weight: 500;padding-left:5px'>元</span></span>` +
              `<span style='font-size: 16px;color: #303133;'>` +
              item[1].data +
              `<span style='font-size: 14px'>笔</span></span></div>`;
            return htmlContxt;
          },
        },
        grid: {
          left: "0",
          right: "0",
          bottom: "0",
          top: "36px",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          min: 0,
          axisLine: {
            lineStyle: {
              color: "#DDDDDD",
            },
          },
          axisTick: {
            lineStyle: {
              color: "#DDDDDD",
            },
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            color: "#999999",
            showMaxLabel: true,
            formatter: function (value) {
              const arr = value.split("-");
              return arr[1] + "-" + arr[2];
            },
          },
          data: xAxis,
        },
        yAxis: {
          type: "value",
          min: 0,
          max: "dataMax",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              color: "#EEEEEE",
              type: "dashed",
            },
          },
        },
        series: [
          {
            data: dataTrading,
            type: "line",
            lineStyle: {
              normal: {
                color: "#FFA900",
                width: 4,
              },
            },
            smooth: true,
          },
          {
            data: dataOrder,
            type: "line",
            lineStyle: {
              normal: {
                color: "#6699FF",
                width: 4,
              },
            },
            smooth: true,
          },
        ],
      };
      const myChart = this.$echarts.init(chartDom);
      myChart.setOption(option, true);
    },
    getTotal() {
      this.params_req.queryStartDate = this.queryStartDate;
      this.params_req.queryEndDate = this.queryEndDate;
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.getStatOrderAmountTotal,
          method: "post",
          params: this.params_req,
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.moneyNum = res.result.data.thisTimeOrderAmount;
            this.orderNum = res.result.data.thisTimeOrderNum;
          } else {
            this.$message.error(res.result.message);
          }
        });
    },
    // 获取图表数据
    getChartData() {
      this.params_req.queryStartDate = this.queryStartDate;
      this.params_req.queryEndDate = this.queryEndDate;
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.getStatOrderAmountList,
          method: "post",
          params: this.params_req,
        })
        .then((res) => {
          if (res.result.code == 0) {
            const xAxis = [];
            const dataTrading = [];
            const dataOrder = [];
            res.result.data.map((item) => {
              xAxis.push(item.fullDateText);
              dataTrading.push(Number((item.orderAmount / 100).toFixed(2)));
              dataOrder.push(item.orderNum);
            });
            // dataTrading[0] = 0.1
            this.drawChart(xAxis, dataTrading, dataOrder);
          } else {
            this.$message.error(res.result.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.tips2 {
  width: 11px;
  height: 11px;
  background: #ffa900;
  border-radius: 3px;
}
.tips {
  width: 11px;
  height: 11px;
  background: #6699ff;
  border-radius: 3px;
}
.mychart {
  width: 100%;
  height: 300px;
}
.tooltips {
  background: #ffffff;
  box-shadow: 0px 5px 21px 0px rgba(139, 147, 167, 0.25);
  opacity: 0.96;
}
.tooltipsTitle {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #606366;
  line-height: 27px;
  padding: 0 25px;
}
.tooltipsContent {
  font-size: 21px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #303133;
  line-height: 32px;
}
</style>
