<!-- 设备安装趋势 -->
<template>
  <div class="a-mt-60" style="padding-bottom: 30px">
    <div class="a-flex-rsbc a-pt-25">
      <div class="a-flex-rfsfe">
        <span class="a-c-master a-fw-700 a-fs-16">设备安装趋势</span>
      </div>
      <div class="a-flex-rfsc">
        <div class="a-flex-rfsc">
          <div
            class="dateType dateType-month a-flex-rcc"
            :class="dateType == 'month' ? 'dateTypeAc' : ''"
            @click="dateTypeChange('month')"
          >
            按月
          </div>
          <div
            class="dateType dateType-year a-flex-rcc"
            :class="dateType == 'year' ? 'dateTypeAc' : ''"
            @click="dateTypeChange('year')"
          >
            按年
          </div>
        </div>
        <el-date-picker
          :key="dateType"
          v-model="dateVal"
          class="a-ml-20 myDatePicker"
          :type="dateType"
          :clearable="false"
          placeholder="请选择"
        ></el-date-picker>
      </div>
    </div>
    <div id="mychartDevIns" class="mychart a-mt-32"></div>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: ["id", "params"],
  data() {
    return {
      dateType: "month",
      dateVal: "",
      params_req: null,
    };
  },
  watch: {
    dateVal(newval, oldval) {
      if (newval != oldval && this.params_req) {
        this.getChartData();
      }
    },
    params: {
      handler(val) {
        this.params_req = JSON.parse(JSON.stringify(val));
        this.getChartData();
      },
    },
  },
  mounted() {
    this.getCurrentDate();
  },
  methods: {
    dateTypeChange(type) {
      this.dateType = type;
      this.getCurrentDate();
    },
    getCurrentDate() {
      const nowDate = new Date();
      this.dateVal = this.$Utils.formatDate(nowDate, this.dateType == "month" ? "yyyy-MM" : "yyyy");
    },
    drawChart(xAxis = [], data = []) {
      const chartDom = document.getElementById("mychartDevIns");

      let option = "";

      option = {
        tooltip: {
          trigger: "axis",
          padding: 0,
          backgroundColor: "transparent",
          formatter: (item) => {
            const htmlContxt =
              `<div class="a-flex-cfsfs" style='padding:10px 18px;background:#FFFFFF;box-shadow: 0px 5px 21px 0px rgba(139,147,167,0.25);border-radius:6px'>
                                <span style='font-size: 14px;color: #606366;'>` +
              item[0].name +
              `</span>` +
              `<span style='font-size: 17px;font-weight: 700;color: #303133;padding:10px 0 0'>` +
              item[0].data +
              `<span style='font-size: 14px;font-weight: 500;padding-left:5px'>台</span></span>`;
            return htmlContxt;
          },
        },
        grid: {
          left: "0",
          right: "16px",
          bottom: "0",
          top: "36px",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          axisLine: {
            lineStyle: {
              color: "#DDDDDD",
            },
          },
          axisTick: {
            lineStyle: {
              color: "#DDDDDD",
            },
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            color: "#999999",
            showMaxLabel: true,
            formatter: function (value) {
              const arr = value.split("-");
              return arr[1] + "-" + arr[2];
            },
          },
          data: xAxis,
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              color: "#EEEEEE",
              type: "dashed",
            },
          },
        },
        // dataZoom: [{
        //     type: 'inside',
        //     zoomOnMouseWheel: true,
        //     zoomLock: false,
        //     state: 0,
        //     end: 30
        // }],
        series: [
          {
            data: data,
            type: "line",
            lineStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0.8, 0.5, 0.5, 1, [
                  {
                    offset: 0,
                    color: "#00C9FF",
                  },
                  {
                    offset: 1,
                    color: "#007AFF",
                  },
                ]),
                width: 4,
              },
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#42BBF60D",
                  },
                  {
                    offset: 1,
                    color: "#FFFFFF",
                  },
                ]),
              },
            },
            smooth: true,
          },
        ],
      };
      const myChart = this.$echarts.init(chartDom);

      myChart.setOption(option, true);
    },
    getChartData() {
      // let nowDate = new Date()
      // let nowDateVal = this.dateVal || this.$Utils.formatDate(nowDate, 'yyyy-MM-dd')
      if (this.dateType == "month") {
        this.params_req["queryStartDate"] = dayjs(this.dateVal)
          .startOf("month")
          .format("YYYY-MM-DD");
        this.params_req["queryEndDate"] = dayjs(this.dateVal).endOf("month").format("YYYY-MM-DD");
      } else if (this.dateType == "year") {
        this.params_req["queryStartDate"] = dayjs(this.dateVal)
          .startOf("year")
          .format("YYYY-MM-DD");
        this.params_req["queryEndDate"] = dayjs(this.dateVal).endOf("year").format("YYYY-MM-DD");
      }
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.getStatDeviceInstallNumList,
          method: "post",
          params: this.params_req,
        })
        .then((res) => {
          if (res.result.code == 0) {
            const xAxis = [];
            const datas = [];
            res.result.data.map((item) => {
              xAxis.push(item.fullDateText);
              datas.push(item.deviceInstallNum);
            });
            this.drawChart(xAxis, datas);
          } else {
            this.$message.error(res.result.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.dateType {
  width: 60px;
  height: 32px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #606366;
  cursor: pointer;
}
.dateType-month {
  border-left: 1px solid #dadde0;
  border-top: 1px solid #dadde0;
  border-bottom: 1px solid #dadde0;
  border-radius: 3px 0px 0px 3px;
}
.dateType-year {
  border-right: 1px solid #dadde0;
  border-top: 1px solid #dadde0;
  border-bottom: 1px solid #dadde0;
  border-radius: 0px 3px 3px 0px;
}
.dateTypeAc {
  border: 1px solid #007aff;
  color: #007aff;
}
.mychart {
  width: 100%;
  height: 300px;
}
</style>
