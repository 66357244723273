<!-- 设备情况 -->
<template>
  <div class="a-mt-60">
    <div class="a-flex-rsbc a-pt-25">
      <div class="a-flex-rfsfe">
        <span class="a-c-master a-fw-700 a-fs-16">设备情况</span>
      </div>
    </div>
    <div class="a-mt-32 border-box-dev a-flex-rsbc a-flex-wrap">
      <div class="a-flex-cfsfs">
        <span class="a-fs-14 a-c-normal">站点总数</span>
        <div class="a-flex-rfsb a-mt-10">
          <span class="a-fs-24 a-c-master a-fw-700">
            {{
              revenueStatistics && revenueStatistics.stationNum ? revenueStatistics.stationNum : 0
            }}
          </span>
        </div>
      </div>

      <div class="a-flex-cfsfs">
        <span class="a-fs-14 a-c-normal">设备总数 / 在线 / 使用中</span>
        <div class="a-flex-rfsb a-mt-10">
          <span class="a-fs-24 a-c-master a-fw-700">
            {{
              revenueStatistics && revenueStatistics.totalDeviceNum
                ? revenueStatistics.totalDeviceNum
                : 0
            }}
            /
            {{
              revenueStatistics && revenueStatistics.onlineDeviceNum
                ? revenueStatistics.onlineDeviceNum
                : 0
            }}
            /
            {{
              revenueStatistics && revenueStatistics.usingDeviceNum
                ? revenueStatistics.usingDeviceNum
                : 0
            }}
          </span>
        </div>
      </div>

      <div class="a-flex-cfsfs" style="margin-right: 200px">
        <span class="a-fs-14 a-c-normal">离线3天以上 / 7天以上 / 30天以上 / 90天以上</span>
        <div class="a-flex-rfsb a-mt-10">
          <span class="a-fs-24 a-c-master a-fw-700">
            {{ offlineNum3 }} / {{ offlineNum7 }} / {{ offlineNum30 }} / {{ offlineNum90 }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id", "revenueStatistics", "params"],
  data() {
    return {
      offlineNum3: 0,
      offlineNum7: 0,
      offlineNum30: 0,
      offlineNum90: 0,
      params_req: "",
    };
  },
  watch: {
    params: {
      handler(val) {
        this.params_req = JSON.parse(JSON.stringify(val));
        this.getTableData();
      },
    },
  },
  methods: {
    getTableData() {
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.getStatOfflineDeviceList2,
          method: "post",
          params: this.params_req,
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.offlineNum3 = res.result.data.offlineNum3 ? res.result.data.offlineNum3 : 0;
            this.offlineNum7 = res.result.data.offlineNum7 ? res.result.data.offlineNum7 : 0;
            this.offlineNum30 = res.result.data.offlineNum30 ? res.result.data.offlineNum30 : 0;
            this.offlineNum90 = res.result.data.offlineNum90 ? res.result.data.offlineNum90 : 0;
          } else {
            this.$message.error(res.result.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.border-box-dev {
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #e3e3e3;
  padding: 32px 43px;
}
</style>
