<!-- 基本信息 -->
<template>
  <div class="a-mt-60" style="padding-bottom: 30px">
    <div class="a-flex-rsbc a-pt-25">
      <div class="a-flex-rfsfe">
        <span class="a-c-master a-fw-700 a-fs-16">基本信息</span>
      </div>
    </div>
    <div class="a-flex-rfsc a-fs-14 a-mt-32">
      <span class="e-title a-c-normal">所属商户</span>
      <span class="e-content a-c-master">
        {{ stationInfo && stationInfo.companyName ? stationInfo.companyName : "" }}
      </span>
    </div>
    <div class="a-flex-rfsc a-fs-14 a-mt-24">
      <span class="e-title a-c-normal">建立时间</span>
      <span class="e-content a-c-master">
        {{ stationInfo && stationInfo.createTime ? stationInfo.createTime : "" }}
      </span>
    </div>
    <div class="a-flex-rfsc a-fs-14 a-mt-32">
      <span class="e-title a-c-normal">开通时间</span>
      <span class="e-content a-c-master">
        {{ stationInfo && stationInfo.openTime ? stationInfo.openTime : "" }}
      </span>
    </div>
    <div class="a-flex-rfsc a-fs-14 a-mt-24">
      <span class="e-title a-c-normal">项目名称</span>
      <span class="e-content a-c-master">
        {{ stationInfo && stationInfo.name ? stationInfo.name : "" }}
      </span>
    </div>
    <div class="a-flex-rfsc a-fs-14 a-mt-24">
      <span class="e-title a-c-normal">站点地址</span>
      <span class="e-content a-c-master">
        {{ stationInfo && stationInfo.address ? stationInfo.address : "" }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["params"],
  data() {
    return {
      params_req: "",
      stationInfo: "",
    };
  },
  watch: {
    params: {
      immediate: true,
      handler(val) {
        this.params_req = JSON.parse(JSON.stringify(val));
        this.getStationInfo();
      },
    },
  },
  methods: {
    getStationInfo() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getStationDetail,
          method: "get",
          params: this.params_req,
        })
        .then((res) => {
          if (res.result.code == 0) {
            console.log(res);
            this.stationInfo = {
              companyName: res.result.data.station.companyName,
              createTime: this.$Utils.formatDate(res.result.data.station.createTime, "yyyy-MM-dd"),
              openTime: res.result.data.station.openTime
                ? this.$Utils.formatDate(res.result.data.station.openTime, "yyyy-MM-dd")
                : "",
              name: res.result.data.incomeDistri.name,
              address: res.result.data.station.address,
            };
          } else {
            this.$message.error(res.result.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.e-title {
  width: 100px;
  padding-right: 16px;
}
</style>
