<!-- 站点-设备情况 -->
<template>
  <div class="a-mt-60">
    <div class="a-flex-rsbc a-pt-25">
      <div class="a-flex-rfsfe">
        <span class="a-c-master a-fw-700 a-fs-16">设备情况</span>
      </div>
    </div>
    <div class="a-mt-32 border-box-dev a-flex-rfsc a-flex-wrap">
      <div class="a-flex-cfsfs" style="margin-right: 200px">
        <span class="a-fs-14 a-c-normal">设备总数</span>
        <div class="a-flex-rfsb a-mt-10">
          <span class="a-fs-24 a-c-master a-fw-700">
            {{
              revenueStatistics && revenueStatistics.totalDeviceNum
                ? revenueStatistics.totalDeviceNum
                : 0
            }}
          </span>
        </div>
      </div>

      <div class="a-flex-cfsfs" style="margin-right: 200px">
        <span class="a-fs-14 a-c-normal">在线设备数</span>
        <div class="a-flex-rfsb a-mt-10">
          <span class="a-fs-24 a-c-master a-fw-700">
            {{
              revenueStatistics && revenueStatistics.onlineDeviceNum
                ? revenueStatistics.onlineDeviceNum
                : 0
            }}
          </span>
        </div>
      </div>

      <div class="a-flex-cfsfs">
        <span class="a-fs-14 a-c-normal">使用中设备数</span>
        <div class="a-flex-rfsb a-mt-10">
          <span class="a-fs-24 a-c-master a-fw-700">
            {{
              revenueStatistics && revenueStatistics.usingDeviceNum
                ? revenueStatistics.usingDeviceNum
                : 0
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["revenueStatistics"],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.border-box-dev {
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #e3e3e3;
  padding: 32px 43px;
}
</style>
